import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Typography } from '@mui/material';

const useClasses = createClasses((theme) => ({
	root: {
		display: 'inline-block',
		verticalAlign: 'text-bottom',
		marginLeft: theme.spacing(1)
	},
	small: {
		fontSize: theme.typography.pxToRem(12),
		borderRadius: theme.spacing(1),
		padding: theme.spacing(.25, 1),
		lineHeight: 1.2
	},
	medium: {
		fontSize: theme.typography.pxToRem(13),
		borderRadius: theme.spacing(2),
		padding: theme.spacing(.5, 1.5),
		lineHeight: 1.3
	},
	large: {
		fontSize: theme.typography.pxToRem(14),
		borderRadius: theme.spacing(3),
		padding: theme.spacing(.75, 2),
		lineHeight: 1.35
	},
	default: {
		backgroundColor: theme.config.palette.grey[theme.config.darkMode ? 300 : 800],
		color: theme.palette.getContrastText(theme.palette.text.primary)
	},
	primary: {
		backgroundColor: theme.config.palette.primary.main,
		color: theme.config.palette.primary.contrastText
	},
	secondary: {
		backgroundColor: theme.config.palette.secondary.main,
		color: theme.config.palette.secondary.contrastText
	},
	...theme.mixins.alertVariants
}), {
	name: 'RaLabel',
	mergeClassName: false
});

function Label(props) {
	const {
		classes: classesProp,
		className,
		children,
		color = 'default',
		size = 'small',
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Typography
			component="span"
			variant="subtitle2"
			color="inherit"
			{...rest}
			className={clsx(
				classes.root,
				classes[color],
				classes[size],
				className
			)}
		>
			{children}
		</Typography>
	);
}

Label.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
	size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default React.memo(Label);
