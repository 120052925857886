import deepmerge from 'deepmerge';
import { forEach } from '../lib/helpers';
import spinner from '../assets/spinner-rolling-thin.svg';
import spinnerSmall from '../assets/spinner-rolling.svg';
import { grey, blue, indigo, pink, red, orange, green } from '@mui/material/colors';
import { colorChannel } from '@mui/system/colorManipulator';
import { keyframes } from '@emotion/react';
import breakpoints from '../context/browser/breakpoints';
import {
	createTheme,
	lighten,
	darken,
	rgbToHex
} from '@mui/material/styles';

export const lightPalette = {
	primary: {
		main: indigo[500],
		light: indigo[300],
		dark: indigo[800]
	},
	secondary: {
		main: pink[600],
		light: pink[300],
		dark: pink[800]
	},
	error: {
		main: red[600],
		light: red[300],
		dark: red[800]
	},
	info: {
		main: blue[600],
		light: blue[300],
		dark: blue[800]
	},
	success: {
		main: green[600],
		light: green[300],
		dark: green[800]
	},
	warning: {
		main: orange[600],
		light: orange[300],
		dark: orange[800]
	},
	default: {
		...grey,
		main: grey[500],
		light: grey[300],
		dark: grey[800]
	}
};

export const darkPalette = {
	primary: {
		main: indigo[300],
		light: indigo[100],
		dark: indigo[500]
	},
	secondary: {
		main: pink[300],
		light: pink[100],
		dark: pink[500]
	},
	error: {
		main: red[200],
		light: red[50],
		dark: red[500]
	},
	info: {
		main: blue[200],
		light: blue[50],
		dark: blue[500]
	},
	success: {
		main: green[200],
		light: green[50],
		dark: green[500]
	},
	warning: {
		main: orange[200],
		light: orange[50],
		dark: orange[500]
	},
	default: {
		...grey,
		main: grey[600],
		light: grey[300],
		dark: grey[800]
	}
};

const backgroundLevelsDark = {
	level1: '#121212',
	level2: '#1e1e1e',
	level3: '#222222',
	level4: '#242424',
	level5: '#272727',
	level6: '#2c2c2c',
	level7: '#2e2e2e',
	level8: '#333333',
	level9: '#353535',
	level10: '#383838'
};

const backgroundLevelsLight = {
	level1: grey[50],
	level2: grey[100],
	level3: grey[200],
	level4: grey[300],
	level5: grey[400],
	level6: grey[500],
	level7: grey[600],
	level8: grey[700],
	level9: rgbToHex(darken(grey[700], .2)),
	level10: grey[800]
};

export const backgroundLevels = {
	...backgroundLevelsDark,
	light: {
		...backgroundLevelsLight
	},
	dark: {
		...backgroundLevelsDark
	}
};

export const breakpointUp = width => `@media (min-width: ${width + 1}px)`;
export const breakpointDown = width => `@media (max-width: ${width}px)`;
export const mapBreakpoints = (breakpoints) => {
	const result = {};

	forEach(breakpoints, (val, name) => {
		result[name] = {
			up: breakpointUp(val),
			down: breakpointDown(val)
		};
	});

	return result;
};

export const containerSpacing = {
	xs: 1.5, sm: 2, md: 3, lg: 4
};
export const gutterSpacing = {
	xs: 1, sm: 1.5, lg: 2
};
export const toolbarSpacing = {
	xs: 1, md: 3, lg: 4
};
export const contentSpacing = {
	xs: 1.5, sm: 2
};
export const layoutSpacing = {
	xs: 2.5, sm: 3.5, md: 4.5, lg: 6
};

export {
	breakpoints,
	createTheme
};

function getColorScheme(mode = 'light') {
	const darkMode = mode === 'dark';
	const palette = darkMode ? darkPalette : lightPalette;

	return {
		palette: {
			default: {
				...palette.default
			},
			primary: {
				...palette.primary
			},
			secondary: {
				...palette.secondary
			},
			error: {
				...palette.error
			},
			info: {
				...palette.info
			},
			success: {
				...palette.success
			},
			warning: {
				...palette.warning
			},
			text: {
				primary: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.87)',
				link: palette.primary.dark
			},
			background: {
				default: darkMode ? '#121212' : '#fff',
				placeholder: palette.primary.dark
			}
		}
	};
}

export const extendTheme = (themeConfig = {}, config = {}) => {
	const {
		mode = themeConfig?.palette?.mode || 'light'
	} = config;

	const lightMode = mode === 'light';
	const darkMode = mode === 'dark';

	const { cssVariables } = themeConfig;

	const colorSchemes = deepmerge({
		light: getColorScheme('light'),
		dark: getColorScheme('dark')
	}, themeConfig?.colorSchemes || {});

	config = deepmerge({
		backgroundLevels,
		lightMode,
		darkMode
	}, config);

	for (let [key, val] of Object.entries(config.backgroundLevels.light)) {
		colorSchemes.light.palette.background[key] = val;
	}
	for (let [key, val] of Object.entries(config.backgroundLevels.dark)) {
		colorSchemes.dark.palette.background[key] = val;
	}

	const typography = {
		fontWeightThin: 100,
		fontWeightExtraLight: 200,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		fontWeightExtraBold: 800,
		fontWeightBlack: 900
	};

	const theme = createTheme(deepmerge({
		breakpoints,
		typography,
		colorSchemes,
		...(cssVariables ? {
			colorSchemeSelector: 'data-theme',
			...cssVariables
		} : {
			palette: {
				mode,
				...colorSchemes[mode].palette
			}
		})
	}, themeConfig));

	const { palette } = theme.colorSchemes[mode];
	const { level10: bgLevel10 } = palette.background;

	const getColorChannel = (color = '') => {
		const [paletteName, colorName = 'main'] = color.split(/-|\./);
		if (color.startsWith('var(--')) {
			return color;
		}
		if (!palette[paletteName]?.[colorName]) {
			return colorChannel(color);
		}
		return theme.config?.palette?.[paletteName]?.[colorName + 'Channel'] || colorChannel(palette[paletteName][colorName] || color);
	};

	theme.transitions.duration.smooth = 500;

	theme.config = {
		colorMode: mode,
		darkMode,
		lightMode,
		containerSpacing,
		toolbarSpacing,
		gutterSpacing,
		layoutSpacing,
		containerMaxWidth: theme.breakpoints.values.desktop + 64,
		spinner,
		spinnerSmall,
		backgroundLevels: config.backgroundLevels,
		contrastColor: palette.getContrastText(palette.text.primary),
		...themeConfig?.config,
		breakpoints: mapBreakpoints({
			...theme.breakpoints.values,
			mobileS: 359,
			mobileM: 375,
			mobileL: 425,
			...config?.breakpoints
		}),
		mediaQueries: {
			touch: '@media (pointer: coarse), (pointer: none), (hover: none)',
			noTouch: '@media (hover: hover) and (pointer: fine)',
			...themeConfig?.config?.mediaQueries
		},
		get palette() {
			return (theme.vars || theme).palette;
		}
	};

	theme.helpers = {
		round: (value) => Math.round(value * 1e5) / 1e5,
		getBreakpoint: (width = 0) => {
			for (const [key = 'xs', value = 0] of Object.entries(theme.breakpoints.values)) {
				if (width >= value) {
					return key;
				}
			}
		},
		colorModeValue: (darkModeValue, lightModeValue) => (
			darkMode ? darkModeValue : lightModeValue
		),
		getColorChannel,
		alpha: (color, opacity) => (
			`rgba(${getColorChannel(color)} / ${opacity})`
		),
		keyframes: {
			fadeIn: keyframes({
				'0%': {
					opacity: 0
				},
				'100%': {
					opacity: 1
				}
			}),
			spinner: keyframes({
				from: {
					opacity: 1,
					transform: 'rotate(0deg)'
				},
				to: {
					opacity: 1,
					transform: 'rotate(360deg)'
				}
			}),
			pulse: keyframes({
				'0%': {
					opacity: 1
				},
				'50%': {
					opacity: 0.4
				},
				'100%': {
					opacity: 1
				}
			}),
			wave: keyframes({
				'0%': {
					transform: 'translateX(-100%)'
				},
				'60%': {
					transform: 'translateX(100%)'
				},
				'100%': {
					transform: 'translateX(100%)'
				}
			})
		}
	};

	theme.mixins = {
		...theme.mixins,
		container: {
			width: '100%',
			margin: '0 auto',
			flexGrow: 1
		},
		gutters: (keys = 'padding', value = contentSpacing) => {
			if (!Array.isArray(keys)) {
				return {[keys]: value};
			}
			return Object.fromEntries(keys.map(key => [key, value]));
		},
		link: {
			...theme.typography.button,
			outline: 'none',
			lineHeight: 'inherit',
			fontSize: 'inherit',
			fontWeight: 'inherit',
			textTransform: 'inherit',
			textDecoration: 'underline',
			color: palette.primary.main,
			textDecorationColor: `rgba(${getColorChannel('primary-main')} / .4)`,
			'&:hover, &:focus, &:active': {
				textDecorationColor: 'inherit'
			},
			'&:focus, &:active': {
				outline: 'auto'
			}
		},
		hide: {
			opacity: 0,
			height: '0 !important',
			minHeight: '0 !important',
			margin: '0 !important',
			padding: '0 !important',
			border: '0 !important'
		},
		spinner: {
			position: 'relative',
			'&:before': {
				backgroundImage: `url(${theme.config.spinner})`,
				backgroundSize: '100px 100px',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
				animation: `${theme.helpers.keyframes.spinner} infinite 1800ms linear`,
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '""'
			}
		},
		scrollbar: {
			[theme.config.mediaQueries.noTouch]: {
				scrollbarWidth: 'thin',
				// scrollbarColor: `${grey[darkMode ? 600 : 500]} ${grey[darkMode ? 800 : 300]}`,
				// scrollbarColor: `${darkMode ? lighten(bgLevel10, .2) : grey[400]} ${darkMode ? bgLevel10 : grey[300]}`,
				/* width */
				'&::-webkit-scrollbar': {
					width: 8,
					height: 8
				},
				/* Track */
				'&::-webkit-scrollbar-track': {
					// background: grey[darkMode ? 800 : 300]
					background: darkMode ? bgLevel10 : grey[300]
					// background: 'transparent'
				},
				/* Corner */
				'&::-webkit-scrollbar-corner': {
					// background: grey[darkMode ? 800 : 300]
					background: darkMode ? bgLevel10 : grey[300]
				},
				/* Handle */
				'&::-webkit-scrollbar-thumb': {
					// border: `solid 1px ${grey[darkMode ? 800 : 300]}`,
					// background: grey[darkMode ? 600 : 500]
					background: darkMode ? lighten(bgLevel10, .2) : grey[400],
					borderRadius: 4
				},
				/* Handle on hover */
				'&::-webkit-scrollbar-thumb:hover': {
					// background: grey[darkMode ? 500 : 600]
					background: darkMode ? lighten(bgLevel10, .3) : grey[500]
				}
			}
		},
		align: {
			centerVH: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			},
			centerV: {
				display: 'flex',
				alignItems: 'center'
			},
			centerH: {
				display: 'flex',
				justifyCointent: 'center'
			}
		},
		animations: {
			fadeIn: {
				transition: theme.transitions.create(['opacity'], {
					easing: theme.transitions.easing.easeIn,
					duration: theme.transitions.duration.standart
				})
			},
			fadeInFast: {
				transition: theme.transitions.create(['opacity'], {
					easing: theme.transitions.easing.easeIn,
					duration: theme.transitions.duration.enteringScreen
				})
			},
			fadeInSlow: {
				transition: theme.transitions.create(['opacity'], {
					easing: theme.transitions.easing.easeIn,
					duration: theme.transitions.duration.complex
				})
			}
		},
		border: {
			top3d: {
				boxShadow: '0 -1px 0 rgba(0,0,0,.4)',
				borderTop: 'solid 1px rgba(255,255,255,.1)'
			},
			bottom3d: {
				boxShadow: '0 1px 0 rgba(255,255,255,.1)',
				borderBottom: 'solid 1px rgba(0,0,0,.4)'
			}
		},
		image: {
			thumbnail: {
				borderRadius: `${theme.shape.borderRadius}px`,
				background: darkMode ? palette.grey[600] : palette.common.white,
				boxShadow: theme.shadows[2]
			}
		},
		fill: {
			position: 'absolute',
			width: '100%',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0
		},
		flexContainer: {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: 'auto',
			display: 'flex',
			width: '100%',
			flexDirection: 'column'
		},
		divider: {
			backgroundImage: 'none',
			backgroundColor: palette.divider
		},
		textOverflow: {
			display: 'inline-block',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: '100%'
		},
		lineClamp: {
			display: '-webkit-box',
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			whiteSpace: 'normal',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
		textShadow: {
			textShadow: '2px 2px 1px rgba(0,0,0,.8)'
		},
		textStroke: (width = 2, color = '#fff') => ({
			'--stroke-width': `${width}px`,
			'--stroke-color': color,
			textShadow: `calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
					var(--stroke-color),
				calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
					var(--stroke-color),
				calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
					var(--stroke-color)`
		}),
		backdropBlur: (value = 0) => ({
			WebkitBackdropFilter: `blur(${value})`,
			MozBackdropFilter: `blur(${value})`,
			MsBackdropFlter: `blur(${value})`,
			backdropFilter: `blur(${value})`
		}),
		hideScrollbar: {
			MsOverflowStyle: 'none', 		// IE 10+
			scrollbarWidth: 'none', 		// Firefox
			'&::-webkit-scrollbar': {		// Safari and Chrome
				display: 'none'
			}
		},
		showScrollbar: {
			MsOverflowStyle: 'initial', 	// IE 10+
			scrollbarWidth: 'thin', 		// Firefox
			'&::-webkit-scrollbar': {		// Safari and Chrome
				display: 'initial'
			}
		},
		alertVariants: {
			error: {
				backgroundColor: palette.error.main,
				color: palette.error.contrastText
			},
			info: {
				backgroundColor: palette.info.main,
				color: palette.info.contrastText
			},
			success: {
				backgroundColor: palette.success.main,
				color: palette.success.contrastText
			},
			warning: {
				backgroundColor: palette.warning.main,
				color: palette.warning.contrastText
			}
		},
		noUserSelect: {
			WebkitUserSelect: 'none',
			MozUserSelect: 'none',
			KhtmlUserSelect: 'none',
			MsUserSelect: 'none',
			userSelect: 'none'
		},
		colorMixBackground: {
			backgroundColor: 'color-mix(in srgb, currentColor 12%, transparent)',
			'&:hover': {
				backgroundColor: 'color-mix(in srgb, currentColor 24%, transparent)'
			}
		},
		colorMixBorder: {
			borderColor: 'color-mix(in srgb, currentColor 50%, transparent)',
			'&:hover': {
				borderColor: 'color-mix(in srgb, currentColor 100%, transparent)'
			}
		},
		colorMixOverlay: {
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: 'color-mix(in srgb, currentColor 12%, transparent)'
			}
		},
		btnBaseVariant: (color = 'primary', opacity = .5, bdrHoverOpacity = 1, bgHoverOpacity = .04) => {
			const [paletteName, colorName = 'main'] = color.split(/-|\./);
			const colorValue = palette[paletteName][colorName];
			const channelValue = getColorChannel(color);

			return {
				color: colorValue,
				borderColor:`rgba(${channelValue} / ${opacity})`,
				'&:hover': {
					borderColor:`rgba(${channelValue} / ${bdrHoverOpacity})`,
					backgroundColor:`rgba(${channelValue} / ${bgHoverOpacity})`
				}
			};
		},
		btnContainedVariant: (color = 'primary', opacity = .23) => {
			const [paletteName, colorName = 'main'] = color.split(/-|\./);
			const colorManipulation = darkMode ? lighten : darken;

			return {
				color: palette[paletteName].contrastText || palette.getContrastText(palette[paletteName][colorName]),
				backgroundColor: palette[paletteName][colorName],
				'&:hover': {
					backgroundColor: colorManipulation(palette[paletteName][colorName], opacity)
				}
			};
		},
		vw: (val = 100) => `calc(var(--vw, 1vw) / 100 * ${val})`,
		vh: (val = 100) => `calc(var(--vh, 1vh) / 100 * ${val})`
	};

	theme.mixins.border.topBottom3d = {
		...theme.mixins.border.top3d,
		...theme.mixins.border.bottom3d,
		boxShadow: `${theme.mixins.border.top3d.boxShadow}, ${theme.mixins.border.bottom3d.boxShadow}`
	};

	theme.mixins.spinnerFadeIn = {
		...theme.mixins.spinner,
		'&:before': {
			...theme.mixins.spinner['&:before'],
			opacity: 0,
			animationDelay: '1s'
		}
	};

	theme.components = deepmerge({
		MuiCssBaseline: {
			styleOverrides: {
				':root': {
					colorScheme: darkMode ? 'dark' : 'light',
					'--app-bar-height': '56px',
					[`${theme.breakpoints.up('xs')}`]: {
						'--app-bar-height': '48px'
					},
					[theme.breakpoints.up('sm')]: {
						'--app-bar-height': '64px'
					}
				},
				html: {
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh'
				},
				body: {
					flexGrow: 1,
					margin: 0,
					padding: 0
				},
				a: {
					outline: 'none',
					color: palette.primary.main,
					textDecorationColor: `rgba(${getColorChannel(`primary-main`)} / .4)`,
					'&:hover, &:focus, &:active': {
						textDecorationColor: 'inherit'
					}
				},
				address: {
					fontStyle: 'normal'
				},
				textarea: {
					...theme.mixins.scrollbar
				}
			}
		},
		MuiIconButton: {
			defaultProps: {
				color: 'inherit'
			},
			styleOverrides: {
				colorInherit: {
					'&&:not(.Mui-disabled)': {
						...theme.mixins.colorMixOverlay
					}
				}
			},
			variants: [{
				props: { variant: 'contained' },
				style: {
					'&&:not(.Mui-disabled)': {
						...theme.mixins.colorMixBackground,
						transition: theme.transitions.create(['background'], {
							duration: theme.transitions.duration.short
						})
					}
				}
			}, {
				props: { variant: 'outlined' },
				style: {
					'&&:not(.Mui-disabled)': {
						borderWidth: 1,
						borderStyle: 'solid',
						...theme.mixins.colorMixOverlay,
						...theme.mixins.colorMixBorder,
						transition: theme.transitions.create(['background', 'border'], {
							duration: theme.transitions.duration.short
						})
					}
				}
			}]
		},
		MuiFab: {
			styleOverrides: {
				default: {
					'&&:not(.Mui-disabled)': {
						color: 'rgba(0, 0, 0, 0.87)',
						backgroundColor: palette.grey[300],
						'&:hover': {
							backgroundColor: palette.grey[400]
						}
					}
				},
				colorInherit: {
					'&&:not(.Mui-disabled)': {
						...theme.mixins.colorMixBackground
					}
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				text: {
					'& > .MuiButton-startIcon': {
						marginLeft: 0
					}
				},
				textSizeSmall: {
					padding: theme.spacing(.5, 1)
				},
				colorInherit: {
					'&&:not(.Mui-disabled)': {
						...theme.mixins.colorMixOverlay,
						'&.MuiButton-outlined': {
							...theme.mixins.colorMixBorder
						},
						'&.MuiButton-contained': {
							...theme.mixins.colorMixBackground
						}
					}
				},
				iconSizeSmall: {
					'& > *:nth-of-type(1)': {
						fontSize:  theme.typography.pxToRem(16),
						marginRight: theme.spacing(-.5)
					}
				},
				iconSizeMedium: {
					'& > *:nth-of-type(1)': {
						fontSize:  theme.typography.pxToRem(18),
						marginRight: theme.spacing(-.25)
					}
				},
				startIcon: {
					marginLeft: 0
				},
				endIcon: {
					marginLeft: theme.spacing(.5)
				}
			},
			variants: [{
				props: { color: 'default' },
				style: {
					...theme.mixins.btnBaseVariant(`default-${darkMode ? 'light' : 'dark'}`, .36, .72)
				}
			}, {
				props: { color: 'default', variant: 'contained' },
				style: {
					...theme.mixins.btnContainedVariant(`default-${darkMode ? 'dark' : 'light'}`, .12)
				}
			}, {
				props: { color: 'info' },
				style: {
					...theme.mixins.btnBaseVariant('info')
				}
			}, {
				props: { color: 'info', variant: 'contained' },
				style: {
					...theme.mixins.btnContainedVariant('info')
				}
			}, {
				props: { color: 'error' },
				style: {
					...theme.mixins.btnBaseVariant('error')
				}
			}, {
				props: { color: 'error', variant: 'contained' },
				style: {
					...theme.mixins.btnContainedVariant('error')
				}
			}, {
				props: { color: 'success' },
				style: {
					...theme.mixins.btnBaseVariant('success')
				}
			}, {
				props: { color: 'success', variant: 'contained' },
				style: {
					...theme.mixins.btnContainedVariant('success')
				}
			}, {
				props: { color: 'warning' },
				style: {
					...theme.mixins.btnBaseVariant('warning')
				}
			}, {
				props: { color: 'warning', variant: 'contained' },
				style: {
					...theme.mixins.btnContainedVariant('warning')
				}
			}, {
				props: { color: 'primary', variant: 'solid' },
				style: {
					color: palette.primary.contrastText,
					backgroundColor: palette.primary.main,
					'&:hover': {
						backgroundColor: palette.primary.dark
					}
				}
			}, {
				props: { color: 'secondary', variant: 'solid' },
				style: {
					color: palette.secondary.contrastText,
					backgroundColor: palette.secondary.main,
					'&:hover': {
						backgroundColor: palette.secondary.dark
					}
				}
			}, {
				props: { color: 'default', variant: 'solid' },
				style: {
					color: palette.default[darkMode ? 900 : 50],
					backgroundColor: palette.default[darkMode ? 500 : 800],
					'&:hover': {
						backgroundColor: palette.default[darkMode ? 600 : 900]
					}
				}
			}],
			defaultProps: {
				color: 'default'
			}
		},
		MuiButtonGroup: {
			styleOverrides: {
				root: {
					'& .MuiButton-outlined.MuiButtonGroup-grouped': {
						...theme.mixins.colorMixBorder,
						'&:not(:first-of-type):not(:hover):after': {
							borderLeftColor: 'transparent'
						},
						'&.Mui-disabled + .MuiButton-outlined.MuiButtonGroup-grouped:after': {
							borderLeftColor: 'inherit'
						}
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				disabled: {
					'& $icon':{
						color: 'inherit'
					}
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					lineHeight: 1.4
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					...theme.mixins.scrollbar,
					backgroundImage: 'none'
				}
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					...theme.mixins.scrollbar
				}
			}
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					backgroundColor: 'inherit'
				}
			}
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'center'
				}
			}
		},
		MuiTypography: {
			variants: [{
				props: { color: 'default' },
				style: {
					color: palette.default.main
				}
			}]
		}
	}, theme.components);

	return theme;
};
