import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { Snackbar } from '@mui/material';
import { Slide } from '@mui/material';
import { Fade } from '@mui/material';
import Button from '../Button';
import NoSsr from '../NoSsr';
import CloseIcon from '@mui/icons-material/Close';

function SlideTransition(props) {
	return <Slide direction="up" {...props} />;
}

const transitions = {
	slide: SlideTransition,
	fade: Fade
};

const useClasses = createClasses((theme, { anchorOrigin }) => ({
	root: {
		zIndex: theme.zIndex.modal,
		textAlign: 'initial',
		maxWidth: 420,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			...(anchorOrigin?.horizontal === 'right' ? {
				marginLeft: 'auto'
			} : {
				marginRight: 'auto'
			})
		},
		[theme.breakpoints.down('phone')]: {
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	anchorOriginTop: {
		marginTop: theme.spacing(2),
		'&&': {
			top: 'var(--app-bar-height)'
		}
	},
	content: {
		'& > .MuiSnackbarContent-message': {
			display: 'flex',
			alignItems: 'center'
		},
		'& > .MuiSnackbarContent-action': {
			'& > *': {
				marginRight: theme.spacing(1)
			},
			'& > *:last-child': {
				marginRight: 0
			}
		}
	},
	default: {},
	primary: {
		backgroundColor: theme.config.palette.primary.dark,
		color: theme.config.palette.primary.contrastText
	},
	secondary: {
		backgroundColor: theme.config.palette.secondary.dark,
		color: theme.config.palette.secondary.contrastText
	},
	...theme.mixins.alertVariants,
	nowrap: {
		flexFlow: 'nowrap',
		'& > .MuiSnackbarContent-action': {
			marginTop: theme.spacing(.5),
			alignSelf: 'flex-start'
		}
	},
	message: {
		fontWeight: 'inherit'
	},
	icon: {
		marginRight: theme.spacing(1.5),
		marginTop: theme.spacing(-.25),
		marginBottom: theme.spacing(-.25)
	}
}), {
	name: 'RaNotification',
	mergeClassName: false
});

function Notification(props) {
	const {
		classes: classesProp,
		className,
		open: openProp,
		defaultOpen = false,
		onClose,
		onClick,
		variant = 'default',
		ContentProps,
		TypographyProps,
		anchorOrigin,
		CloseButtonProps,
		ExtraButtonProps,
		extraButtonLabel = 'mehr erfahren',
		closeButtonLabel,
		ButtonProps: ButtonPropsProp,
		TransitionProps,
		action: actionProp,
		clickaway = false,
		nowrap,
		transition,
		message,
		Icon,
		...rest
	} = props;

	const [openState, setOpenState] = React.useState(defaultOpen);
	const {current: isControlled} = React.useRef(typeof openProp === 'boolean');
	const open = isControlled ? openProp : openState;

	const classes = useClasses(props);

	const showCloseButton = onClose || !isControlled;
	const showExtraButton = onClick || Boolean(ExtraButtonProps);

	const {
		vertical = 'bottom',
		horizontal = 'left'
	} = anchorOrigin || {};

	const ButtonProps = {
		color: 'inherit',
		size: 'small',
		...ButtonPropsProp
	};

	function handleClose(event, reason) {
		if (clickaway === false && reason === 'clickaway') {
			return;
		}

		if (!isControlled) {
			setOpenState(false);
		}

		if (onClose) {
			onClose(event);
		}
	}

	const action = actionProp || [
		(showExtraButton ? (
			<Button
				key="more"
				onClick={onClick}
				{...ButtonProps}
				{...ExtraButtonProps}
			>
				{extraButtonLabel}
			</Button>
		) : []),

		(showCloseButton ? (
			closeButtonLabel ? (
				<Button
					key="close"
					onClick={handleClose}
					{...ButtonProps}
					{...CloseButtonProps}
				>
					{closeButtonLabel}
				</Button>
			) : (
				<IconButton
					key="close"
					color="inherit"
					size="small"
					onClick={handleClose}
					{...ButtonProps}
					{...CloseButtonProps}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			)
		) : [])
	];

	return (
		<NoSsr disabled={defaultOpen}>
			<Snackbar
				{...rest}
				open={open}
				anchorOrigin={{
					vertical,
					horizontal
				}}
				onClose={handleClose}
				action={action}
				TransitionProps={{
					appear: !defaultOpen,
					...TransitionProps
				}}
				TransitionComponent={transitions[transition]}
				message={(
					<>
						{Icon &&
							<Icon className={classes.icon}/>
						}
						<Typography
							variant="body2"
							component="div"
							{...TypographyProps}
							className={classes.message}
						>
							{message}
						</Typography>
					</>
				)}
				ContentProps={{
					className: clsx(
						classes.content,
						classes[variant],
						nowrap !== false && !closeButtonLabel && !showExtraButton && classes.nowrap
					),
					...ContentProps
				}}
				className={clsx(
					classes.root,
					vertical === 'top' && classes.anchorOriginTop,
					className
				)}
			/>
		</NoSsr>
	);
}

Notification.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	anchorOrigin: PropTypes.object,
	ContentProps: PropTypes.object,
	TypographyProps: PropTypes.object,
	ButtonProps: PropTypes.object,
	TransitionProps: PropTypes.object,
	CloseButtonProps: PropTypes.object,
	ExtraButtonProps: PropTypes.object,
	extraButtonLabel: PropTypes.string,
	closeButtonLabel: PropTypes.string,
	defaultOpen: PropTypes.bool,
	clickaway: PropTypes.bool,
	nowrap: PropTypes.bool,
	action: PropTypes.node,
	Icon: PropTypes.elementType
};

export default React.memo(Notification);
