/* global WEBPACK_DEV WEBPACK_IS_PUBLIC WEBPACK_IS_CZECH */

import { createDataRoute } from 'react-app/context/page-data';
import loadable from '@loadable/component';
import PageLoading from 'components/PageLoading';
import App from 'components/App';
import Error from 'components/Error';
import NotFound from './NotFound';
import { appData } from 'config/data.cjs';

const loadableConfig = {
	fallback: <PageLoading/>
};

const News = loadable(() => import('./News'), loadableConfig);
const TermsOfBusiness = loadable(() => import('./TermsOfBusiness'), loadableConfig);
const PrivacyPolicy = loadable(() => import('./PrivacyPolicy'), loadableConfig);
const Imprint = loadable(() => import('./Imprint'), loadableConfig);
const Admin = loadable(() => import('./Admin'), loadableConfig);
const Test = WEBPACK_DEV ? loadable(() => import('./Test'), loadableConfig) : NotFound;

const { pages, isCzech } = appData;

let routes = [];
let AppLayout;

if (WEBPACK_IS_CZECH || isCzech) {
	AppLayout = loadable(() => import('./Czech/CzechAppLayout'), loadableConfig);

	const Home = loadable(() => import('./Czech/CzechHome'), loadableConfig);
	const Assortment = loadable(() => import('./Czech/CzechAssortment'), loadableConfig);
	const Contact = loadable(() => import('./Czech/CzechContact'), loadableConfig);

	routes = [
		createDataRoute({
			id: pages.home.id,
			path: pages.home.pathname,
			lazy: async () => ({ Component: Home })
		}),
		createDataRoute({
			id: pages.assortment.id,
			path: pages.assortment.pathname,
			lazy: async () => ({ Component: Assortment })
		}),
		createDataRoute({
			id: pages.contact.id,
			path: pages.contact.pathname,
			lazy: async () => ({ Component: Contact })
		})
	];
} else {
	AppLayout = loadable(() => import('components/AppLayout'), loadableConfig);

	const Home = loadable(() => import('./Home'), loadableConfig);
	const Sale = loadable(() => import('./Sale'), loadableConfig);
	const GiftItems = loadable(() => import('./GiftItems'), loadableConfig);
	const Assortment = loadable(() => import('./Assortment'), loadableConfig);
	const FactoryHome = loadable(() => import('./Factory/FactoryHome'), loadableConfig);
	const FactoryAssortment = loadable(() => import('./Factory/FactoryAssortment'), loadableConfig);
	const Discontinued = loadable(() => import('./Discontinued'), loadableConfig);
	const Exhibition = loadable(() => import('./Exhibition'), loadableConfig);
	const Service = loadable(() => import('./Service'), loadableConfig);
	const Team = loadable(() => import('./Team'), loadableConfig);
	const Events = loadable(() => import('./Events'), loadableConfig);
	const Catalogs = loadable(() => import('./Catalogs'), loadableConfig);
	const Configurators = loadable(() => import('./Configurators'), loadableConfig);
	const Partner = loadable(() => import('./Partner'), loadableConfig);
	const SampleShipping = loadable(() => import('./SampleShipping'), loadableConfig);
	const CraftsmanAgency = loadable(() => import('./CraftsmanAgency'), loadableConfig);
	const Jobs = loadable(() => import('./Jobs'), loadableConfig);
	const History = loadable(() => import('./History'), loadableConfig);
	const Contact = loadable(() => import('./Contact'), loadableConfig);
	const Consultation = loadable(() => import('./Consultation'), loadableConfig);

	const eventsRoutes = [];
	const assortmentRoutes = [];
	const excludeAssortmentIds = appData.pages.assortment?.options?.excludeIds || [];

	for ( let [id, page] of Object.entries(appData.pages)) {
		if (page.pathname && !page?.options?.dropDownMenuOnly) {
			if (page.dataId === 'events') {
				eventsRoutes.push(createDataRoute({
					id: page.id,
					path: page.match || page.pathname,
					lazy: async () => ({ Component: Events })
				}));
			} else if (id.startsWith('assortment') && !excludeAssortmentIds.includes(id)) {
				assortmentRoutes.push(createDataRoute({
					id: page.id,
					path: page.pathname,
					lazy: async () => ({ Component: id.startsWith('assortment10_') ? FactoryAssortment : Assortment })
				}));
			}
		}
	}

	routes = [
		...eventsRoutes,
		...assortmentRoutes,
		createDataRoute({
			id: pages.home.id,
			path: pages.home.pathname,
			element: <Home/>
		}),
		createDataRoute({
			id: pages.sale.id,
			path: pages.sale.match,
			lazy: async () => ({ Component: Sale })
		}),
		createDataRoute({
			id: pages.giftItems.id,
			path: pages.giftItems.match,
			lazy: async () => ({ Component: GiftItems })
		}),
		createDataRoute({
			id: pages.discontinued.id,
			path: pages.discontinued.pathname,
			lazy: async () => ({ Component: Discontinued })
		}),
		createDataRoute({
			id: pages.exhibition.id,
			path: pages.exhibition.pathname,
			lazy: async () => ({ Component: Exhibition })
		}),
		createDataRoute({
			id: pages.exhibition1.id,
			path: pages.exhibition1.pathname,
			lazy: async () => ({ Component: Exhibition })
		}),
		createDataRoute({
			id: pages.exhibition2.id,
			path: pages.exhibition2.pathname,
			lazy: async () => ({ Component: Exhibition })
		}),
		createDataRoute({
			id: pages.exhibition3.id,
			path: pages.exhibition3.pathname,
			lazy: async () => ({ Component: Exhibition })
		}),
		createDataRoute({
			id: pages.exhibition4.id,
			path: pages.exhibition4.pathname,
			lazy: async () => ({ Component: Exhibition })
		}),
		createDataRoute({
			id: pages.service.id,
			path: pages.service.pathname,
			lazy: async () => ({ Component: Service })
		}),
		createDataRoute({
			id: pages.team.id,
			path: pages.team.pathname,
			lazy: async () => ({ Component: Team })
		}),
		createDataRoute({
			id: pages.catalogs.id,
			path: pages.catalogs.pathname,
			lazy: async () => ({ Component: Catalogs })
		}),
		createDataRoute({
			id: pages.configurators.id,
			path: pages.configurators.pathname,
			lazy: async () => ({ Component: Configurators })
		}),
		createDataRoute({
			id: pages.partner.id,
			path: pages.partner.pathname,
			lazy: async () => ({ Component: Partner })
		}),
		createDataRoute({
			id: pages.jobs.id,
			path: pages.jobs.pathname,
			lazy: async () => ({ Component: Jobs })
		}),
		createDataRoute({
			id: pages.history.id,
			path: pages.history.pathname,
			lazy: async () => ({ Component: History })
		}),
		createDataRoute({
			id: pages.contact.id,
			path: pages.contact.match,
			lazy: async () => ({ Component: Contact })
		}),
		createDataRoute({
			id: pages.service2.id,
			path: pages.service2.match,
			lazy: async () => ({ Component: Consultation })
		}),
		createDataRoute({
			id: pages.assortment10.id,
			path: pages.assortment10.pathname,
			element: <FactoryHome/>
		}),
		...(!WEBPACK_IS_PUBLIC ? [
			createDataRoute({
				id: pages.sampleShipping.id,
				path: pages.sampleShipping.pathname,
				lazy: async () => ({ Component: SampleShipping })
			}),
			createDataRoute({
				id: pages.craftsmanAgency.id,
				path: pages.craftsmanAgency.pathname,
				lazy: async () => ({ Component: CraftsmanAgency })
			})
		] : [])
	];
}

export default [{
	element: <App/>,
	children: [{
		element: <AppLayout/>,
		children: [
			...routes,
			createDataRoute({
				id: pages.news.id,
				path: pages.news.match,
				lazy: async () => ({ Component: News })
			}),
			createDataRoute({
				id: pages.termsOfBusiness.id,
				path: pages.termsOfBusiness.pathname,
				lazy: async () => ({ Component: TermsOfBusiness })
			}),
			createDataRoute({
				id: pages.privacyPolicy.id,
				path: pages.privacyPolicy.pathname,
				lazy: async () => ({ Component: PrivacyPolicy })
			}),
			createDataRoute({
				id: pages.imprint.id,
				path: pages.imprint.pathname,
				lazy: async () => ({ Component: Imprint })
			}),
			createDataRoute({
				id: pages.admin.id,
				path: pages.admin.match,
				lazy: async () => ({ Component: Admin })
			}),
			...(WEBPACK_DEV ? [
				createDataRoute({
					id: pages.test.id,
					path: pages.test.pathname,
					lazy: async () => ({ Component: Test })
				})
			] : []), {
				path: '*',
				element: <NotFound/>
			}
		].map(item => ({
			...item,
			errorElement: item.errorElement || <Error/>
		}))
	}]
}];
