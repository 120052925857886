import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Avatar as MuiAvatar } from '@mui/material';
import Img from '../Img';

const defaultProps = {
	color: 'default'
};

const useClasses = createClasses((theme, {width, height}) => ({
	root: {
		width: width || theme.spacing(6),
		height: height || theme.spacing(6),
		'& > *': {
			color: 'inherit'
		},
		'&.MuiAvatar-square': {
			backgroundColor: 'transparent'
		}
	},
	default: {
		backgroundColor: theme.config.palette.background.placeholder,
		color: theme.config.palette.text.primary
	},
	primary: {
		backgroundColor: theme.config.palette.primary.main,
		color: theme.config.palette.primary.contrastText
	},
	secondary: {
		backgroundColor: theme.config.palette.secondary.main,
		color: theme.config.palette.secondary.contrastText
	},
	image: {
		width: 'fit-content',
		minWidth: 'fit-content',
		maxWidth: theme.spacing(6),
		maxHeight: theme.spacing(6),
		fallbacks: {
			width: '-moz-fit-content',
			minWidth: '-moz-fit-content'
		}
	}
}), {
	name: 'RaAvatar',
	mergeClassName: false
});

function Avatar(props) {
	const {
		classes: classesProp,
		className,
		children,
		color = defaultProps.color,
		src,
		ImgProps,
		width,
		height,
		ImgComponent = Img,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<MuiAvatar
			{...rest}
			className={clsx(
				classes.root,
				color && classes[color],
				className
			)}
		>
			{src ? (
				<ImgComponent
					src={src}
					width={width}
					height={height}
					objectFit="contain"
					objectPosition="left"
					{...ImgProps}
					classes={{
						...ImgProps?.classes,
						img: clsx(
							classes.image,
							ImgProps?.classes?.img
						)
					}}
				>
					{children}
				</ImgComponent>
			) : (
				children
			)}
		</MuiAvatar>
	);
}

Avatar.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	dark: PropTypes.bool,
	ImgProps: PropTypes.object,
	ImgComponent: PropTypes.elementType,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(Avatar);
