import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import NavButton from '../NavButton';
import NavDots from '../NavDots';
import { useDidMount, useMemoCallback } from '../../hooks';
import { useBrowser } from '../../context/browser';

const useClasses = createClasses((theme, {
	fullWidth,
	centered,
	centerScrollSnap,
	navButtonOffsetX,
	navButtonOffsetY
}) => ({
	root: {
		position: 'relative',
		width: '100%'
	},
	container: {
		position: 'relative',
		display: 'flex',
		flexFlow: 'wrap',
		width: '100%'
	},
	track: {
		overflowX: 'auto',
		overflowY: 'hidden',
		display: 'flex',
		width: '100%',
		maxHeight: '100%',
		transform: 'translateZ(0)',
		scrollSnapType: 'x mandatory',
		MsOverflowStyle: 'none',
		scrollbarWidth: 'none',
		WebkitOverflowScrolling: 'touch',
		WebkitTouchCallout: 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		...(fullWidth && {
			minWidth: '100%'
		})
	},
	slide: {
		display: 'flex',
		flexDirection: 'column',
		scrollSnapAlign: 'start',
		...(fullWidth && {
			marginLeft: 0,
			marginRight: 0,
			minWidth: '100%',
			maxWidth: '100%',
			scrollSnapAlign: 'start',
			scrollSnapStop: 'always'
		}),
		...(centered && {
			'&:first-of-type': {
				marginLeft: 'auto'
			},
			'&:last-of-type': {
				marginRight: 'auto'
			}
		}),
		...(centerScrollSnap && {
			scrollSnapAlign: 'center',
			scrollMarginLeft: 0,
			scrollMarginRight: 0
		})
	},
	fullWidth: {},
	centered: {},

	navButton: {
		...(navButtonOffsetY && {
			marginTop: navButtonOffsetY
		})
	},
	navButtonPrev: {
		...(navButtonOffsetX && {
			marginLeft: navButtonOffsetX
		})
	},
	navButtonNext: {
		...(navButtonOffsetX && {
			marginRight: navButtonOffsetX
		})
	},

	navDots: {
		visibility: 'hidden'
	},
	navDot: {
		'&[tabindex="0"]': {
			'&:after': {
				opacity: 1
			}
		}
	},
	activeNavDot: {}
}), {
	name: 'RaScrollableViews'
});

function Visible(props) {
	const {
		children,
		containerRef,
		trackRef,
		renderOnlyVisible = true
	} = props;

	const [visible, setVisible] = React.useState(false);
	const browser = useBrowser();

	React.useEffect(() => {
		const isSrollable = trackRef.current?.scrollWidth > containerRef.current?.clientWidth;
		if (isSrollable !== visible) {
			setVisible(isSrollable);
		}
	}, [browser, containerRef, trackRef, visible]);

	if (!renderOnlyVisible) {
		return visible ? React.Children.map(children, (child, key) => (
			React.cloneElement(child, {
				key,
				style: {visibility: 'visible'}
			})
		)) : children;
	}

	return visible ? children : null;
}

const ScrollableViews = React.forwardRef(function ScrollableViews(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		fullWidth,
		showNavDots,
		showNavButtons = true,
		centered,
		cursor,
		defaultCursor = 0,
		animated = true,
		loop = true,
		NavButtonProps,
		NavDotsProps,
		component: Component = 'div',
		onRest,
		onScroll,
		variableSlideMode,
		navButtonOffsetX,
		navButtonOffsetY,
		centerScrollSnap,
		...rest
	} = props;

	const containerRef = React.useRef();
	const trackRef = React.useRef();
	const dotsRef = React.useRef();
	const leftArrowRef = React.useRef();
	const rightArrowRef = React.useRef();
	const slideNodes = React.useRef(new Map()).current;
	const slidesVisibility = React.useRef(new Map()).current;

	const classes = useClasses(props);

	const slides = React.Children.map(children, (child, key) => {
		const ref = inst => inst === null
			? slideNodes.delete(key)
			: slideNodes.set(key, inst);

		return (
			<div
				key={key}
				ref={ref}
				data-key={key}
				className={classes.slide}
			>
				{child}
			</div>
		);
	});

	const slidesCount = slides.length;

	const getScrollPosition = useMemoCallback(() => {
		const trackNode = trackRef.current;
		return trackNode ? trackNode.scrollLeft : null;
	});

	const getCurrentSlide = useMemoCallback(() => {
		const position = getScrollPosition();
		for (let [key, slide] of slideNodes) {
			if (slide.offsetLeft >= position) {
				return key;
			}
		}
		return 0;
	});

	const getVisibleSlides = useMemoCallback(() => {
		if (fullWidth) {
			return 1;
		}

		if (variableSlideMode) {
			let visibleSlides = 0;
			for (let [key, isVisible] of slidesVisibility) {
				if (isVisible) {
					visibleSlides++;
				}
			}
			return visibleSlides || 1;
		}

		for (let [key, slide] of slideNodes) {
			if (slide.offsetLeft > containerRef.current?.clientWidth) {
				if (key - 1 > 0) {
					return key - 1;
				}
				return key;
			}
		}
		return 1;
	});

	const getSlideWidth = useMemoCallback((index) => {
		const slideNode = slideNodes.get(index);
		if (slideNode) {
			slideNode.clientWidth;
		}
		return 0;
	});

	const isStartOfScroll = useMemoCallback(() => {
		const trackNode = trackRef.current;
		return trackNode.scrollLeft === 0;
	});

	const isEndOfScroll = useMemoCallback(() => {
		const trackNode = trackRef.current;
		return trackNode.offsetWidth + getSlideWidth(slidesCount - 1) + trackNode.scrollLeft >= trackNode.scrollWidth;
	});

	const doSliding = useMemoCallback((direction = 'next') => {
		let currentSlide = getCurrentSlide();
		const isScrollEnd = isEndOfScroll();
		const visibleSlides = getVisibleSlides();

		if (direction === 'next') {
			if (isScrollEnd) {
				currentSlide = 0;
			} else {
				currentSlide += visibleSlides;
			}
		} else if (currentSlide === 0) {
			currentSlide = slidesCount - 1;
		} else {
			currentSlide -= visibleSlides;
		}

		if (currentSlide < 0 || currentSlide >= slidesCount) {
			currentSlide = 0;
		}

		gotoSlide(currentSlide);
	});

	const gotoSlide = useMemoCallback((index, animate = animated) => {
		const trackNode = trackRef.current;
		const slideNode = slideNodes.get(index);
		if (trackNode && slideNode) {
			const position = slideNode.offsetLeft;
			if (animate) {
				trackNode.scroll({
					left: position,
					behavior: 'smooth'
				});
			} else {
				trackNode.scrollLeft = position;
			}
		}
	});

	const prevSlide = useMemoCallback(() => {
		doSliding('prev');
	});

	const nextSlide = useMemoCallback(() => {
		doSliding('next');
	});

	React.useEffect(() => {
		if (Number.isFinite(defaultCursor)) {
			gotoSlide(defaultCursor, false);
		}
		if (Number.isFinite(cursor) && cursor !== getCurrentSlide()) {
			gotoSlide(cursor);
		}
	}, [cursor, defaultCursor, getCurrentSlide, gotoSlide]);

	useDidMount(() => {
		if (typeof onRest === 'function' || typeof onScroll === 'function') {
			let isScrolling;
			trackRef.current?.addEventListener('scroll',  event => {
				if (onRest) {
					clearTimeout(isScrolling);
					isScrolling = setTimeout(() => {
						onRest(getCurrentSlide(), event);
					}, 66);
				}
				if (onScroll) {
					onScroll(event);
				}
			}, false);
		}

		const observer1 = !loop || (!fullWidth && variableSlideMode) ? new IntersectionObserver((entries, observer) => {
			for (let entry of entries) {
				if (!fullWidth && variableSlideMode) {
					slidesVisibility.set(entry.target.getAttribute('data-key'), entry.isIntersecting);
				}
				if (!loop && leftArrowRef.current && rightArrowRef.current) {
					if (isStartOfScroll()) {
						leftArrowRef.current.style.display = 'none';
					} else if (leftArrowRef.current.style.display) {
						leftArrowRef.current.style.removeProperty('display');
					}
					if (isEndOfScroll()) {
						rightArrowRef.current.style.display = 'none';
					} else if (rightArrowRef.current.style.display) {
						rightArrowRef.current.style.removeProperty('display');
					}
				}
			}
		}, {
			root: trackRef.current,
			threshold: 1
		}) : null;

		const observer2 = showNavDots ? new IntersectionObserver((entries, observer) => {
			const { children: dots = [] } = dotsRef.current || {};
			for (let entry of entries) {
				if (entry.isIntersecting) {
					const index = +entry.target.getAttribute('data-key');
					for (let i = 0; i < dots.length; i++){
						dots[i].setAttribute('tabindex', i === index ? 0 : -1);
					}
					return;
				}
			}
		}, {
			root: trackRef.current,
			threshold: fullWidth ? 0.51 : 1
		}) : null;

		for (let [key, slide] of slideNodes) {
			if (slide) {
				if (observer1) {
					observer1.observe(slide);
				}
				if (observer2) {
					observer2.observe(slide);
				}
			}
		}

		return () => {
			if (observer1) {
				observer1.disconnect();
			}
			if (observer2) {
				observer2.disconnect();
			}
		};
	});

	React.useImperativeHandle(ref, () => ({
		container: containerRef.current,
		track: trackRef.current,
		dots: dotsRef,
		slideNodes,
		getCurrentSlide,
		getVisibleSlides,
		gotoSlide,
		prevSlide,
		nextSlide
	}));

	return (
		<Component
			{...rest}
			ref={ref}
			className={classes.root}
		>
			<div
				ref={containerRef}
				className={clsx(
					classes.container,
					fullWidth && classes.fullWidth,
					centered && classes.centered
				)}
			>
				<div
					ref={trackRef}
					className={classes.track}
				>
					{slides}
				</div>
				{showNavButtons && (
					<Visible
						key={slidesCount}
						trackRef={trackRef}
						containerRef={containerRef}
					>
						<NavButton
							flat
							hideOnTouchDevices
							{...NavButtonProps}
							{...(!loop && {
								style: {
									display: 'none',
									...NavButtonProps?.style
								}
							})}
							ref={leftArrowRef}
							onClick={prevSlide}
							className={clsx(
								classes.navButton,
								classes.navButtonPrev
							)}
						/>
						<NavButton
							flat
							hideOnTouchDevices
							{...NavButtonProps}
							ref={rightArrowRef}
							onClick={nextSlide}
							className={clsx(
								classes.navButton,
								classes.navButtonNext
							)}
							direction="right"
						/>
					</Visible>
				)}
			</div>
			{showNavDots && slidesCount > 1 && (
				<Visible
					trackRef={trackRef}
					containerRef={containerRef}
					renderOnlyVisible={false}
				>
					<NavDots
						{...NavDotsProps}
						ref={dotsRef}
						className={classes.navDots}
						classes={{
							...NavDotsProps?.classes,
							dot: classes.navDot,
							activeDot: classes.activeNavDot
						}}
						slidesCount={slidesCount}
						gotoSlide={gotoSlide}
					/>
				</Visible>
			)}
		</Component>
	);
});

ScrollableViews.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	cursor: PropTypes.number,
	defaultCursor: PropTypes.number,
	NavDotsProps: PropTypes.object,
	NavButtonProps: PropTypes.object,
	animated: PropTypes.bool,
	loop: PropTypes.bool,
	fullWidth: PropTypes.bool,
	centered: PropTypes.bool,
	showNavDots: PropTypes.bool,
	showNavButtons: PropTypes.bool,
	variableSlideMode: PropTypes.bool,
	centerScrollSnap: PropTypes.bool,
	onRest: PropTypes.func,
	onScroll: PropTypes.func,
	navButtonOffsetX: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	navButtonOffsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
};

export default React.memo(ScrollableViews);
