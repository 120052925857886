import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import { Drawer as  MuiDrawer } from '@mui/material';
import { IconButton } from '@mui/material';
import Hidden from '../Hidden';

const getScrollToActiveItem = (ref) => (init = true) => {
	const paperNode = ref.current;
	const config = {
		block: 'center',
		inline: 'start'
	};

	if (paperNode) {
		const activeMenu = paperNode.querySelector('[data-active="true"]');

		if (activeMenu) {
			try {
				if (init) {
					const viewportOffset = activeMenu.getBoundingClientRect();
					if (viewportOffset.top < 0 || viewportOffset.top > paperNode.clientHeight - 64) {
						activeMenu.scrollIntoView(config);
					}
				} else {
					activeMenu.scrollIntoView(config);
				}
			} catch (e) {}
		}
	}
};

const useClasses = createClasses((theme, {hideScrollbar}) => ({
	root: {},
	drawerPaper: {
		flexGrow: 1,
		flexDirection: 'column',
		overflow: 'hidden'
	},
	drawerInner: {
		scrollbarGutter: 'stable',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflowX: 'hidden',
		overflowY: 'auto',
		paddingRight: theme.spacing(1),
		[theme.config.mediaQueries.noTouch]: {
			...theme.mixins.scrollbar,
			'@supports (scrollbar-gutter: stable)': {
				paddingRight: 0
			},
			...(hideScrollbar && {
				'&::-webkit-scrollbar-track': {
					background: 'transparent'
				},
				'&&:not(:hover)': {
					'&::-webkit-scrollbar-corner': {
						background: 'transparent'
					},
					'&::-webkit-scrollbar-thumb': {
						background: 'transparent'
					}
				}
			})
		}
	},
	drawerHeader: {
		'&&': {
			...theme.mixins.toolbar
		},
		boxSizing: 'content-box',
		backgroundColor: theme.config.palette.background.paper,
		display: 'flex',
		flexWrap: 'wrap',
		flexShrink: 0
	},
	drawerFooter: {
		backgroundColor: theme.config.palette.background.paper,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 'auto',
		marginBottom: 0
	},
	headerContent: {
		'&&': {
			...theme.mixins.toolbar
		},
		boxSizing: 'border-box',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(1.5),
			paddingRight: theme.spacing(1.5)
		}
	},
	footerContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1.5)
		}
	},
	headerCloseButton: {
		display: 'flex',
		alignSelf: 'center',
		padding: theme.spacing(1),
		marginLeft: 'auto',
		marginRight: theme.spacing(1),
		[`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
			marginRight: theme.spacing(.5)
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: theme.spacing(1.5)
		}
	},
	fixedHeader: {
		top: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar
	},
	fixedFooter: {
		bottom: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar
	},
	divider: {
		width: '100%'
	}
}), {
	name: 'RaAppDrawer'
});

function DrawerHeader(props) {
	const {
		classes,
		header,
		headerContent,
		headerDivider,
		fixedHeader,
		HiddenHeaderProps,
		headerCloseButton,
		onClose
	} = props;

	const content = header || headerContent || headerCloseButton ? (
		<div className={clsx(
			classes.drawerHeader,
			(fixedHeader || headerCloseButton) && classes.fixedHeader
		)}>
			{headerContent &&
				<div className={classes.headerContent}>
					{headerContent}
				</div>
			}
			{headerCloseButton &&
				<IconButton
					color="inherit"
					className={classes.headerCloseButton}
					onClick={onClose}
				>
					<CloseIcon/>
				</IconButton>
			}
			{headerDivider &&
				<Divider className={classes.divider}/>
			}
		</div>
	) : null;

	return HiddenHeaderProps && content ? (
		<Hidden {...HiddenHeaderProps}>
			{content}
		</Hidden>
	) : content;
}

function DrawerFooter(props) {
	const {
		classes,
		footer,
		footerContent,
		footerDivider,
		fixedFooter,
		HiddenFooterProps
	} = props;

	const content = footer || footerContent ? (
		<div
			className={clsx(
				fixedFooter && classes.fixedFooter,
				classes.drawerFooter
			)}
		>
			{footerDivider &&
				<Divider className={classes.divider}/>
			}
			{footerContent &&
				<div className={classes.footerContent}>
					{footerContent}
				</div>
			}
		</div>
	) : null;

	return HiddenFooterProps && content ? (
		<Hidden {...HiddenFooterProps}>
			{content}
		</Hidden>
	) : content;
}

const Drawer = React.forwardRef(function AppDrawer(props, ref) {
	const paperRef = React.useRef();
	const scrollToActiveItem = getScrollToActiveItem(paperRef);

	const {
		classes: classesProp,
		className,
		children,
		open,
		onClose,
		DrawerComponent = MuiDrawer,
		PaperProps: PaperPropsProp,
		headerCloseButton,
		headerDivider,
		footerDivider,
		headerContent,
		footerContent,
		fixedHeader,
		fixedFooter,
		header,
		footer,
		HiddenHeaderProps,
		HiddenFooterProps,
		drawerWidth = 300,
		variant = 'temporary',
		hideScrollbar,
		drawerKey,
		...rest
	} = props;

	const classes = useClasses(props);

	const DrawerClasses = React.useMemo(() => ({
		paper: classes.drawerPaper
	}), [classes.drawerPaper]);

	const PaperProps = React.useMemo(() => ({
		variant: 'elevation',
		...PaperPropsProp,
		ref: paperRef,
		...(variant !== 'temporary' ? {
			style: {width: drawerWidth}
		} : null)
	}), [paperRef, PaperPropsProp, variant, drawerWidth]);

	React.useImperativeHandle(ref, () => ({
		scrollToActiveItem
	}));

	const temporaryDrawerProps = React.useMemo(() => ({
		...(variant === 'temporary' ? {
			keepMounted: true
		} : null)
	}), [variant]);

	return (
		<DrawerComponent
			key={drawerKey || variant}
			anchor="left"
			{...rest}
			{...temporaryDrawerProps}
			variant={variant}
			classes={DrawerClasses}
			className={classes.root}
			open={open}
			onClose={onClose}
			PaperProps={PaperProps}
		>
			{fixedHeader && (
				<DrawerHeader {...props} classes={classes} />
			)}
			<nav
				className={classes.drawerInner}
				style={{...(variant === 'temporary' ? {
					width: drawerWidth
				} : null)}}
			>
				{!fixedHeader && (
					<DrawerHeader {...props} classes={classes} />
				)}
				{children}
				{!fixedFooter && (
					<DrawerFooter {...props} classes={classes} />
				)}
			</nav>
			{fixedFooter && (
				<DrawerFooter {...props} classes={classes} />
			)}
		</DrawerComponent>
	);
});

Drawer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	header: PropTypes.bool,
	footer: PropTypes.bool,
	fixedHeader: PropTypes.bool,
	fixedFooter: PropTypes.bool,
	headerContent: PropTypes.node,
	footerContent: PropTypes.node,
	headerDivider: PropTypes.bool,
	footerDivider: PropTypes.bool,
	hideScrollbar: PropTypes.bool,
	headerCloseButton: PropTypes.bool,
	HiddenHeaderProps: PropTypes.object,
	HiddenFooterProps: PropTypes.object,
	DrawerComponent: PropTypes.elementType
};

export default React.memo(Drawer);
