import { useDialog } from 'react-app/components/DialogProvider';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Stack from '@mui/material/Stack';
import HeroBanner, { HeroBannerLogo, HeroBannerHeadline, HeroBannerButton } from 'components/HeroBanner';
import { logo_hw_outlined, logo_hw_retail_outlined, default1_banner, wood1_banner } from 'config/images';
import { useDeferredUser } from 'context/user';

const LogoProps = {
	width: {xs: 200, md: 300}
};
const HeroBannerProps = {
	sx: {
		'&&': {
			maxHeight: 250,
			minHeight: 250
		}
	}
};

export default function HomePageHeroBanner() {
	const [ {isRetail} ] = useDeferredUser();
	const { openDialog } = useDialog();

	return (
		<HeroBanner {...HeroBannerProps} image={isRetail ? default1_banner : wood1_banner}>
			<HeroBannerLogo ImgProps={isRetail ? logo_hw_retail_outlined : logo_hw_outlined} LogoProps={LogoProps} justifyContent="space-around">
				<HeroBannerHeadline
					gutterTop={false}
					title={isRetail ? 'Ihr Holzfachmarkt mit Ausstellung' : 'Ihr Holzgroßhandel mit Ausstellung'}
					subtitle={isRetail ? 'für Heimwerker und Profis' : 'für Handwerker und Architekten'}
					component="h1"
				>
					<Stack direction={{sm: 'row'}} marginTop={{xs: 2, lg: 3}} marginX="auto">
						<HeroBannerButton
							onClick={openDialog}
							data-src="https://youtube.com/embed/6ZAVQocEx0U?autoplay=1&rel=0"
							data-title="Wir sind Holz Weidauer - Unsere Firmengruppe stellt sich vor"
							startIcon={<YouTubeIcon/>}
						>
							Wir stellen uns vor
						</HeroBannerButton>
					</Stack>
				</HeroBannerHeadline>
			</HeroBannerLogo>
		</HeroBanner>
	);
}
