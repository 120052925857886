import { createClasses } from 'styles';
import Stack from '@mui/material/Stack';
import PageSection from 'components/PageSection';
import { HeroBannerHeadline, HeroBannerButton } from 'components/HeroBanner';
import bgImage from 'assets/images/misc/Gutschein_Weidauer.png';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

const PageHeadlineProps = {
	sx: {'&&': {
		justifyContent: 'center'
	}}
};

const ContainerProps = {
	sx: {
		backgroundColor: '#2f362b',
		background: `url(${bgImage}) center center, radial-gradient(circle farthest-side, #4e4f61, #3b3c4a, #262735, #252633)`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '251px 200px, auto',
		backgroundBlendMode: 'soft-light'
	}
};

const useClasses = createClasses((theme) => ({
	heading: {
		...theme.mixins.limeText
	},
	subheading: {
		fontWeight: theme.typography.fontWeightMedium
	},
	button: {
		...theme.mixins.limeButton,
		margin: 0
	}
}), {
	name: 'GiftCardSection'
});

export default function GiftCardSection(props) {
	const {
		ContainerProps: ContainerPropsProp,
		PageHeadlineProps: PageHeadlinePropsProp,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<PageSection
			{...rest}
			ContainerProps={{...ContainerProps, ...ContainerPropsProp}}
			PageHeadlineProps={{...PageHeadlineProps, ...PageHeadlinePropsProp}}
		>
			<Stack gap={{xs: 2, lg: 3}}>
				<HeroBannerHeadline
					noTextStroke
					classes={{
						root: classes.heading,
						subheading: classes.subheading
					}}
					title="Freude schenken!"
					subtitle="mit dem Holz Weidauer Geschenk-Gutschein"
				/>
				<Stack direction={{sm: 'row'}} gap={{xs: 2, lg: 3}} margin="auto">
					<HeroBannerButton
						className={classes.button}
						color="primary"
						href="https://www.holzweidauer.shop/p/geschenk-gutschein/HL1981601/?itemId=7002033897&pmzr=12027"
						startIcon={<LoyaltyIcon/>}
					>
						zum Gutschein
					</HeroBannerButton>
				</Stack>
			</Stack>
		</PageSection>
	);
}
